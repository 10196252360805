// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
// import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
// import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
// import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
// // import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
// import ArticleIcon from '@mui/icons-material/Article';
// import StorefrontIcon from '@mui/icons-material/Storefront';
// import DiscFullIcon from '@mui/icons-material/DiscFull';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const menuItems = [
  // {
  //   heading: 'CrossUp',
  //   items: [
  //     {
  //       name: 'CrossUp',
  //       icon: AddShoppingCartIcon,
  //       link: 'dashboards/crossup/panel',
  //       // items: [{
  //       //   name: 'Panel',
  //       //   icon: ArticleIcon,
  //       //   link: 'dashboards/crossup/panel',
  //       // },
  //       // {
  //       //   name: 'Estadisticas',
  //       //   icon: AnalyticsTwoToneIcon,
  //       //   link: 'dashboards/crossup/estadisticas',
  //       // },
  //       // ],
  //     },
  //   ]
  // },
  {
    heading: 'Gifty',
    items: [
      {
        name: 'Gifty',
        icon: CardGiftcardIcon,
        link: 'dashboards/gifty/panel',
        // items: [{
        //   name: 'Panel',
        //   icon: ArticleIcon,
        //   link: 'dashboards/gifty/panel',
        // },
        // {
        //   name: 'Estadisticas',
        //   icon: AnalyticsTwoToneIcon,
        //   link: 'dashboards/gifty/estadisticas',
        // },
        // ],
      },
    ]
  },
  {
    heading: 'WhatsPRO',
    items: [
      {
        name: 'WhatsPRO',
        icon: WhatsAppIcon,
        link: 'dashboards/whatspro',
  //         name: 'Estadisticas',
  //         icon: AnalyticsTwoToneIcon,
  //         link: 'dashboards/whatspro/estadisticas',
  //       },
  //       ],
      },
    ]
  },
  {
    heading: 'Comercial',
    items: [
      {
        heading: 'Comercial',
        icon: AddBusinessIcon,
        link: 'dashboards/comercial',
      },
    ]
  },
  {
    heading: 'Facturacion',
    items: [
      {
        heading: 'Facturacion',
        icon: AccountBalanceWalletIcon,
        link: 'dashboards/facturacion',
      },
    ]
  },
  {
    heading: 'Analiticas',
    items: [
      {
        heading: 'Analiticas',
        icon: QueryStatsIcon,
        link: 'dashboards/analiticas',
      },
    ]
  }
];

export default menuItems;
