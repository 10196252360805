import { mock } from 'src/utils/axios';

let partners = [
    {
        id: '1',
        displayName: 'La Paloma',
        address: 'Olavarria 344',
        totalBookings: 450,
        totalCubiertosIngresados: 1250,
        bookingNoShow: 42,
        bookingRejected: 32,
        bookingDeletedPartner: 63,
        linkSuscripcion: 'https://www.google.com',
        logo: '/static/images/placeholders/products/1.png',
    },
    {
        id: '2',
        displayName: 'Bruder',
        address: 'Guemes 134',
        totalBookings: 302,
        totalCubiertosIngresados: 1550,
        bookingNoShow: 2,
        bookingRejected: 0,
        bookingDeletedPartner: 35,
        linkSuscripcion: 'https://www.facebook.com',
        logo: '/static/images/placeholders/products/2.png',
    },
    {
        id: '3',
        displayName: 'Caverna de Saint Lawrence',
        address: 'San Martin 54',
        totalBookings: 100,
        totalCubiertosIngresados: 341,
        bookingNoShow: 23,
        bookingRejected: 0,
        bookingDeletedPartner: 0,
        linkSuscripcion: 'https://www.instagram.com',
        logo: '/static/images/placeholders/products/3.png',
    },
    {
        id: '4',
        displayName: 'Casa Pampa',
        address: 'Punta Mogotes 344',
        totalBookings: 23,
        totalCubiertosIngresados: 353,
        bookingNoShow: 2,
        bookingRejected: 3,
        bookingDeletedPartner: 6,
        linkSuscripcion: 'https://www.ibm.com',
        logo: '/static/images/placeholders/products/4.png',
    },
    {
        id: '5',
        displayName: 'HELTER',
        address: 'Stevenson 976',
        totalBookings: 23,
        totalCubiertosIngresados: 500,
        bookingNoShow: 2,
        bookingRejected: 3,
        bookingDeletedPartner: 1,
        linkSuscripcion: 'https://www.playground.digitalhouse.com',
        logo: '/static/images/placeholders/products/5.png',
    },
    {
        id: '6',
        displayName: 'Lo de Fran',
        address: 'Perple 6',
        totalBookings: 300,
        totalCubiertosIngresados: 894,
        bookingNoShow: 1,
        bookingRejected: 0,
        bookingDeletedPartner: 0,
        linkSuscripcion: 'https://www.woki.ar',
        logo: '/static/images/placeholders/products/6.png',
    },
    {
        id: '7',
        displayName: 'Bar Vader',
        address: 'Jena 952',
        totalBookings: 1063,
        totalCubiertosIngresados: 4531,
        bookingNoShow: 365,
        bookingRejected: 234,
        bookingDeletedPartner: 122,
        linkSuscripcion: 'https://www.example.com',
        logo: '/static/images/placeholders/products/7.png',
    },
    {
        id: '8',
        displayName: 'Gansos rosas',
        address: 'Ganio 75',
        totalBookings: 2341,
        totalCubiertosIngresados: 3547,
        bookingNoShow: 0,
        bookingRejected: 0,
        bookingDeletedPartner: 0,
        linkSuscripcion: 'https://www.gansos.com',
        logo: '/static/images/placeholders/products/8.png',
    },
    {
        id: '9',
        displayName: 'Tio Escurzio',
        address: 'Belgrano 44',
        totalBookings: 23,
        totalCubiertosIngresados: 213,
        bookingNoShow: 1,
        bookingRejected: 1,
        bookingDeletedPartner: 1,
        linkSuscripcion: 'https://www.pipe.com',
        logo: '/static/images/placeholders/products/9.png',
    },
    {
        id: '10',
        displayName: 'Javier Milei',
        address: 'San Luis 1729',
        totalBookings: 23,
        totalCubiertosIngresados: 345,
        bookingNoShow: 23,
        bookingRejected: 32,
        bookingDeletedPartner: 25,
        linkSuscripcion: 'https://www.javiermilei.com',
        logo: '/static/images/placeholders/products/10.png',
    },
    {
        id: '11',
        displayName: 'Melwokinks',
        address: 'Oligrado 79',
        totalBookings: 76,
        totalCubiertosIngresados: 346,
        bookingNoShow: 53,
        bookingRejected: 14,
        bookingDeletedPartner: 12,
        linkSuscripcion: 'https://www.melba.com',
        logo: '/static/images/placeholders/fitness/1.jpg',
    },
];

mock.onGet('/api/partners').reply(() => {
    return [200, { partners }];
});

mock.onGet('/api/partner').reply((config) => {
    const { partnerId } = config.params;
    const partner = partners.find((_partner) => _partner.id === partnerId);

    return [200, { partner }];
});